import React, { Component } from 'react'; //different
//import './style.css';
import jwt_decode from "jwt-decode";
import instance, { preoductionurl } from "./axios-instance";
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
import { json } from 'react-router-dom';
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mainsetting: [],
            rewardlist: [],
            activePage: 1,
            pagecount: 1,
            products_page_count: 1,
            isActive: false,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            stateopterror: "",
            stateregis: 1,
            username: null,
            checkboxacc: 0,
            listacc: [],
            userid: null,
            phoneNumber: "",
            userrecomend: "",
            balance: 0,
            mainacc: null,
        }
        this.forgetotpandregister = this.forgetotpandregister.bind(this);
    }
    checkaccdeposit(listitem) {
        this.setState({
            mainacc: listitem,
        });
    }
    confirmdeposit = async (e, num) => {

        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;

        await instance.post("/api/v1/getredeempromotion", {
            userid: Username,
            System: Systems,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                this.setState({
                    proderedeem: res.data.message.tblpromotionredeems,
                });

            }
        }).catch(async (err) => {
        });

        if (num === 0) {
            if ((this.state.depositamount >= this.state.limit.min) && (this.state.depositamount <= this.state.limit.max)) {
                const { depositstate } = this.state
                ////////console.log(depositstate, num);
                if (num === 0) {
                    if (token != null) {
                        instance.post("/api/v1/getqrcode", {
                            username: Username,
                            System: Systems,
                            amount: this.state.depositamount
                        }, {
                            headers: { Authorization: `Bearer ${token}` }
                        }).then(async (res) => {
                            ////////console.log(res.data.picture)
                            this.setState({
                                mainacc: res.data.mainacc,
                                qrcode: res.data.picture
                            });
                        });
                    }

                    this.setState({
                        depositstate: 1
                    });
                }
            }
            else {
                await Swal.fire({
                    title: 'Warning!',
                    text: 'အနည်းဆုံးဖြည့်စွက်  ' + this.state.limit.min + ' အများဆုံး ' + this.state.limit.max + ' ',
                    icon: 'warning',
                    confirmButtonText: 'သဘောတူသည်။'
                })
            }
        }
        else if (num === 1) {
            this.setState({
                depositstate: 1
            });
        }
        else {
            this.setState({
                depositstate: 0
            });
        }



    }
    forgetotpandregister = async (type) => {
        const { phoneNumber, otpphoneforget, otpphone } = this.state;
        var recheck = false;
        if (type === 4) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;
            });
        }
        else if (type === 5) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                pin: otpphoneforget,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;

            });
        }
        else if (type === 6) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;
            });
        }
        else if (type === 7) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                pin: otpphone,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;

            });
        }
        return recheck
    }
    nextstep = async (e, num) => {
        e.preventDefault();
        const { stateregis, phoneNumber, checkboxacc, fname, otpphone, accnumbertxt,mainacc } = this.state

        console.log(num);
        if (num === 1) {
            const check = await this.forgetotpandregister(6);
            console.log(1, phoneNumber.length, check.checked);
            if (phoneNumber.length === 11 && check.checked) {
                this.setState({
                    stateregis: 2
                });
            }
            else {
                if (phoneNumber.length !== 11) {
                    this.setState({
                        stateopterror: "သင်သည် နံပါတ်မှားရိုက်ထည့်လိုက်သည်။"
                    });
                }
                else if (!check.checked && check.status === 201) {
                    this.setState({
                        stateopterror: "ဤနံပါတ်ကို အသုံးပြုနေပြီဖြစ်သည်။"
                    });
                }
                else {
                    this.setState({
                        stateopterror: "သင်သည် မကြာခဏ ငွေပေးချေမှုများ ပြုလုပ်သည်။ ခဏစောင့်ပါ။"
                    });
                }

                document.getElementById("phoneNumber").style = "border-color: #ef4444;";
                document.getElementById("checkotpforgetphonenormal").style.display = "block";
            }
        }
        else if (num === 2) {

            if ( mainacc != null && fname !== "" && accnumbertxt !== "") {
                this.setState({
                    stateregis: 3
                });
            }
            else {
                await Swal.fire({
                    title: 'Warning!',
                    text: 'ဘဏ်ကိုရွေးပါ။ သို့မဟုတ် အချက်အလက်အပြည့်အစုံထည့်ပါ။ !!',
                    icon: 'warning',
                    confirmButtonText: 'သဘောတူသည်။'
                })
                //////console.log(2, stateregis, checkboxacc);
            }
        }
        else if (num === 3) {
            //////console.log(1, stateregis, num);
            this.setState({
                stateregis: 4
            });
        }
        else if (num === 5) {
            var check = await this.forgetotpandregister(7);
            if (otpphone !== "" && check.checked) {
                this.setState({
                    stateregis: 2
                });
            }
            else if (otpphone === "" || otpphone.length < 4) {
                this.setState({
                    stateopterror: "သင်သည် မပြည့်စုံသော နံပါတ်များကို ထည့်သွင်းခဲ့သည်။"
                });
                document.getElementById("otpphone").style = "border-color: #ef4444;";
                document.getElementById("checkotp").style.display = "block";
            }

            else {
                this.setState({
                    stateopterror: "အမှားကုဒ်"
                });
                document.getElementById("otpphone").style = "border-color: #ef4444;";
                document.getElementById("checkotp").style.display = "block";
            }



        }
        else if (num === 11) {
          if(this.state.mainacc == null)
            {
                this.setState({
                    stateopterror: "ဘဏ်ကို သင်မရွေးချယ်ရသေးပါ။"
                });
                document.getElementById("phoneNumber").style = "border-color: #ef4444;";
                document.getElementById("checkotpforgetphonenormal").style.display = "block";
                return;
            }
            else{
                this.setState({
                    stateregis: 1
                });
            }
         
        }
        else if (num === 22) {
            //////console.log(1, stateregis, num);
            this.setState({
                stateregis: 2
            });
        }
    };

    onChangex = event => {
        // console.log("event", event.target.name);


        if (event.target !== undefined) {
            let nam;
            let val;

            if (event.target.name === undefined) {
                nam = event.name;
                val = event.value;
            } else {
                nam = event.target.name;
                val = event.target.value;
            }
            this.setState({ [nam]: val });
        }
    };

    async modalerror(object) {
        await Swal.fire({
            title: object.title + "!",
            text: object.text,
            icon: object.title,
            confirmButtonText: 'ပြုလုပ်မည်'
        })
    }
    validatergisterForm = async (e) => {
        e.preventDefault();
        const { phoneNumber, fname, password, passwordcf, mainacc, accnumbertxt } = this.state;

        //////console.log(checkboxacc);
        //////////console.log(this.state)
        if (phoneNumber === "") {

            var title = 'Error!'
            var text = 'မင်းမထည့်ဘူး။ တယ်လီဖုန်းနံပါတ်'
            var object = { title, text }
            this.modalerror(object);

        }
        else if (accnumbertxt === "") {
            title = 'Error!'
            text = 'သင့်ဘဏ်အကောင့်နံပါတ်ကို သင်မထည့်ခဲ့ပါ။'
            object = { title, text }
            this.modalerror(object);

        }
        else if (fname === "") {

            title = 'Error!'
            text = 'မင်းနာမည်မထည့်ဘူး။'
            object = { title, text }
            this.modalerror(object);

        }
        else if (mainacc === null) {
            title = 'Error!'
            text = 'ဘဏ်ကို သင်မရွေးချယ်ရသေးပါ။'
            object = { title, text }
            this.modalerror(object);
        }
        else if (password !== passwordcf) {
            title = 'Error!'
            text = 'သင့်စကားဝှတ်ထည့်တာ ၂ကြိမ်လုံးမကိုက်ညီပါ ကျေးဇူးပြု၍မှန်အောင်ပြန်ထည့်ပါ '
            object = { title, text }
            this.modalerror(object);
        }

        else {
            instance.post("/api/v1/register", {
                User_id: phoneNumber,
                name: fname,
                password: password,
                accbank: mainacc.bankid,
                accno: accnumbertxt,
                System: Systems,
                Useradvise: this.state.userrecomend
            })
                .then(async (res) => {
                    if (res.data.status === 200) {
                        await Swal.fire({
                            title: 'Success!',
                            text: 'လျှောက်လွှာပြီးပါပြီ။',
                            icon: 'success',
                            confirmButtonText: 'သဘောတူသည်။'
                        })
                        await instance.post("/api/v1/userlogin", {
                            userid: phoneNumber,
                            password: password,
                            System: Systems,
                            Function: 'validateLoginForm-navbar.js'
                        }).then((res) => {
                            if (res.status === 200) {
                                if (res.data.status === 200) {
                                    localStorage.setItem('auth_token', res.data.token);
                                    window.location.href = '/'
                                    // window.location.replace('https://play.bossy777.com?login=' + res.data.token);
                                    //  window.location.replace(preoductionurl() + '?login=' + res.data.token);
                                    // window.location.replace('http://localhost:3001?login=' + null);
                                }
                            }
                        });
                    }
                    else if (res.data.status === 202) {
                        this.setState({
                            errorstatus: 1,
                            errortext: 'ဤအသုံးပြုသူအမည်ကို မှတ်ပုံတင်ပြီးဖြစ်သည်။'
                        });
                    }
                    else {
                        this.setState({
                            errorstatus: 1,
                            errortext: 'အပလီကေးရှင်းရှိ အသုံးပြုသူအမည် သို့မဟုတ် အမည်သည် မမှန်ပါ။'
                        });
                    }
                });
        }


    };


    async saveclickaff(linkid) {
        await instance.post("/api/v1/saveclickaff", {
            linkid: linkid,
            System: Systems,
        }).then((res) => {
            if (res.data.status == 200) {
                var date = new Date()
                this.setState({
                    userrecomend: res.data.username,
                    link: linkid
                });
                var username = res.data.username
                var object = { date, username }
                localStorage.setItem('aff', JSON.stringify(object))
            }
        }).catch(async (err) => {
        });

    }
    async saveclick(linkid) {
        await instance.post("/api/v1/saveclickaff", {
            linkid: linkid,
            System: Systems,
        }).then((res) => {
            if (res.data.status == 200) {
                var date = new Date()
                var username = res.data.username
                this.setState({
                    userrecomend: username,
                });
                var object = { date, username }
                localStorage.setItem('aff', JSON.stringify(object))
            }
        }).catch(async (err) => {
        });

    }
    async componentDidMount() {
        var token = localStorage.getItem('auth_token');
        // if (token != null) {
        //     console.log("register")
        //     localStorage.removeItem('auth_token', '')
        // }

        const search = window.location.href;
        var test = search.split("aff=")[1]
        if (test != undefined) {
            this.saveclickaff(test.split("&")[0]);
        }
        var params = search.split("reg=")[1]
        if (params != undefined) {
            this.saveclick(params);
            this.setState({
                userrecomend: params.split("&")[0],
            });
        }


        var tokenf = localStorage.getItem('aff');
        if (tokenf != null) {
            var codev = JSON.parse(tokenf)
            //   console.log(codev);
            var Username = codev.username;
            this.setState({
                userrecomend: Username,
            });
        }

        var tokenx = localStorage.getItem('register');
        if (tokenx != null) {
            var codev = JSON.parse(tokenx)
            // console.log(codev);
            var Username = codev.username;
            this.setState({
                userrecomend: Username,
            });
        }


        await instance.post("/api/v1/mainsetting", {
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                const datas = res.data.message;
                var min = res.data.tblcatagoryx[0].limitdeposit;
                var max = res.data.tblcatagoryx[0].maxdeposit;
                var limit = { min, max }
                this.setState({
                    linelink: datas[1].value,
                    System: res.data.tblcatagoryx[0].System,
                    listacc: res.data.tblmain,
                    limit: limit
                });
            }
            else {
                // this.CheckThisGoalError();
            }
        });
    }





    render() {

        const { stateopterror, userrecomend, listacc, mainacc } = this.state

        //console.log(prodetail.length)
        return (
            <>
                <div style={{ marginTop: "200px" }}>
                    <div className="-full-flex-wrap ">
                        <div
                            className="-full-flex-inner-bg -bg"
                        >
                            <div className="x-promotion-index">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="x-page-title-component ">
                                                <div className="-inner-wrapper">
                                                    <h1 className="-title">အကောင့်သစ်ဖွင့်</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div
                                                // data-animatable="fadeInRegister"
                                                className="col order-lg-2 order-0 -form  fadeInRegisterform"
                                            >
                                                <div className="x-modal-separator-container ">
                                                    <div className="-top " style={{ display: this.state.stateregis === 1 ? "" : "none" }}>
                                                        <h3 className="x-title-modal mx-auto text-center ">
                                                            မိမိဖုန်းနံပါတ်
                                                        </h3>
                                                        <div className="-fake-inner-body">
                                                            <div className="x-step-register -v2">
                                                                <div className="px-0 m-auto -container-wrapper">
                                                                    <div className={"-step-box-outer" + (this.state.stateregis !== 1 ? "" : " step-active")}></div>
                                                                    <div className={"-step-box-outer" + (this.state.stateregis !== 2 ? "" : " step-active")}></div>
                                                                    <div className={"-step-box-outer" + (this.state.stateregis !== 2 ? "" : " step-active")}></div>
                                                                    <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                                                </div>
                                                            </div>
                                                            <div >
                                                                <div className="-x-input-icon mb-3 text-center">
                                                                    <img
                                                                        src="/build/web/igame-index-lobby-wm/img/ic-input-phone.png"
                                                                        className="-icon"
                                                                        alt="SA Casino phone icon"
                                                                        width={10}
                                                                        height={14}
                                                                    />
                                                                    <input
                                                                        onChange={this.onChangex}
                                                                        type="text"
                                                                        id="phoneNumber"
                                                                        name="phoneNumber"
                                                                        required="required"
                                                                        pattern="[0-9]*"
                                                                        className="x-form-control form-control"
                                                                        placeholder="မိမိဖုန်းနံပါတ်"
                                                                        autoFocus="autoFocus"
                                                                        autoComplete="on"
                                                                        inputMode="text"
                                                                    />
                                                                    <div id="checkotpforgetphonenormal" className="invalid-feedback ">
                                                                        <ul className="list-unstyled mb-0"><li>{stateopterror}</li></ul>
                                                                    </div>
                                                                </div>
                                                                {/* <h3 className="x-title-modal mx-auto text-center " style={{ marginBottom: "15px" }}>
                                                                အကြံပြုထားသော ပုဂ္ဂိုလ်။
                                                                </h3>
                                                                <div className="-x-input-icon mb-3 text-center">
                                                                    <img
                                                                        src="/build/web/igame-index-lobby-wm/img/ic-user-v2.png"
                                                                        className="-icon"
                                                                        alt="SA Casino phone icon"
                                                                        width={10}
                                                                        height={14}
                                                                    />
                                                                    <input
                                                                        onChange={this.onChangex}
                                                                        readOnly={true}
                                                                        style={{ color: "black", background: "transparent" }}
                                                                        type="text"
                                                                        id="userrecomend"
                                                                        name="userrecomend"
                                                                        required="required"
                                                                        value={userrecomend}
                                                                        className="x-form-control form-control"
                                                                        placeholder="အကြံပြုထားသော ပုဂ္ဂိုလ်။"
                                                                        autoFocus="autoFocus"
                                                                        autoComplete="on"
                                                                        inputMode="text"
                                                                    />
                                                                </div> */}
                                                                <div className="text-center">
                                                                    <button
                                                                        className="btn btn-primary mt-lg-3 mr-lg-3 "
                                                                        disabled=""
                                                                    >
                                                                        <div className="-text-container"> <div className="textwidget"><a
                                                                            onClick={(e) => {
                                                                                this.nextstep(e, 1)
                                                                            }}>နောက်တစ်ဆင့်</a></div></div>
                                                                    </button>
                                                                </div>
                                                                <input
                                                                    type="hidden"
                                                                    id="request_otp__token"
                                                                    name="request_otp[_token]"
                                                                    defaultValue="ds26nEjb1GacmW8racC7ScvGiCgTEnPl5xKWBzZyMWc"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="-top " style={{ display: this.state.stateregis === 5 ? "" : "none" }}>
                                                        <h3 className="x-title-modal mx-auto text-center ">
                                                            အတည်ပြုပါ။ OTP
                                                        </h3>
                                                        <div className="-img-container ">
                                                            <picture>
                                                                <source
                                                                    type="image/webp"
                                                                    srcSet="https://sacasino.co/build/web/ezc-sa-casino/img/register-icon-otp-v2.webp"
                                                                />
                                                                <source
                                                                    type="image/png?v=5"
                                                                    srcSet="https://sacasino.co/build/web/ezc-sa-casino/img/register-icon-otp-v2.webp"
                                                                />
                                                                <img
                                                                    alt="กรอกรหัส OTP เพื่อအတည်ပြုပါ။ตัวตน"
                                                                    className="img-fluid -ic-otp"
                                                                    width={150}
                                                                    height={150}
                                                                    src="https://sacasino.co/build/web/ezc-sa-casino/img/register-icon-otp-v2.webp"
                                                                />
                                                            </picture>
                                                        </div>

                                                        <div className="-fake-inner-body">
                                                            <div className="x-step-register -v2">
                                                                <div className="px-0 m-auto -container-wrapper">
                                                                    <div className={"-step-box-outer" + (this.state.stateregis !== 5 ? "" : " step-active")}></div>
                                                                    <div className={"-step-box-outer" + (this.state.stateregis !== 5 ? "" : " step-active")}></div>
                                                                    <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                                                    <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                                                </div>
                                                            </div>
                                                            <div >
                                                                <div className="-x-input-icon mb-3 text-center">
                                                                    <img
                                                                        src="/build/web/igame-index-lobby-wm/img/ic-input-phone.png"
                                                                        className="-icon"
                                                                        alt="SA Casino phone icon"
                                                                        width={10}
                                                                        height={14}
                                                                    />
                                                                    <input
                                                                        onChange={this.onChangex}
                                                                        type="text"
                                                                        id="otpphone"
                                                                        name="otpphone"
                                                                        required="required"
                                                                        pattern="[0-9]*"
                                                                        maxLength="4"
                                                                        className="x-form-control form-control"
                                                                        placeholder="กรุณากรอก OTP 4 หลัก"
                                                                        autoFocus="autoFocus"
                                                                        autoComplete="on"
                                                                        inputMode="text"
                                                                    />
                                                                    <div id="checkotp" className="invalid-feedback ">
                                                                        <ul className="list-unstyled mb-0"><li>{stateopterror}</li></ul>
                                                                    </div>
                                                                </div>
                                                                <div className="text-center">
                                                                    <a href="#0" style={{ cursor: "pointer" }}
                                                                        onClick={(e) => {
                                                                            this.nextstep(e, 11)
                                                                        }} className="text-muted mt-3">
                                                                        ပယ်ဖျက်ခြင်း
                                                                    </a>
                                                                </div>
                                                                <div className="text-center">

                                                                    <button
                                                                        onClick={(e) => {
                                                                            this.nextstep(e, 5)
                                                                        }}
                                                                        type="submitx"
                                                                        className="btn   btn-primary mt-lg-3 mt-0"
                                                                        disabled=""
                                                                    >
                                                                        နောက်တစ်ဆင့်
                                                                    </button>
                                                                </div>
                                                                <input
                                                                    type="hidden"
                                                                    id="request_otp__token"
                                                                    name="request_otp[_token]"
                                                                    defaultValue="ds26nEjb1GacmW8racC7ScvGiCgTEnPl5xKWBzZyMWc"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="-top " style={{ display: this.state.stateregis === 2 ? "" : "none" }}>
                                                        <h3 className="x-title-modal mx-auto text-center ">
                                                            ဘဏ်အမျိုးအစားကိုရွေးပါ
                                                        </h3>
                                                        <div className="">
                                                            <div
                                                                data-animatable="fadeInModal"
                                                                className="-animatable-container animated fadeInModal"
                                                            >
                                                                <div className="text-center -img-container">
                                                                    <div className="-title mt-3">   ဘဏ်အမျိုးအစားကိုရွေးပါ </div>
                                                                </div>
                                                                <div className="x-step-register -v2">
                                                                    <div className="px-0 m-auto -container-wrapper">
                                                                        <div className={"-step-box-outer" + (this.state.stateregis !== 2 ? "" : " step-active")}></div>
                                                                        <div className={"-step-box-outer" + (this.state.stateregis !== 2 ? "" : " step-active")}></div>
                                                                        <div className={"-step-box-outer" + (this.state.stateregis !== 2 ? "" : " step-active")}></div>
                                                                        <div className={"-step-box-outer" + (this.state.stateregis !== 1 ? "" : " step-active")}></div>
                                                                    </div>
                                                                </div>
                                                                <div id="card-bank-info">
                                                                    <div className="text-center">
                                                                        <div className="my-3 js-bank-select-container">
                                                                            <div className="x-bank-choices-type imglist ">
                                                                                <div className="x-bank-choices-type ">
                                                                                    <div className="-outer-wrapper" id='checkboxacc' onChange={this.onChangex}>
                                                                                        {listacc.map((listitem, i) => (
                                                                                            <img
                                                                                                role='button'
                                                                                                className={mainacc === null ? "-logo disable-bank" : mainacc.no === listitem.no ? "-logo" : "-logo disable-bank"}
                                                                                                id={listitem.no}
                                                                                                style={{marginRight:"5px"}}
                                                                                                src={listitem.img_path}
                                                                                                width="100%"
                                                                                                onClick={(e) => this.checkaccdeposit(listitem)}
                                                                                            />
                                                                                        ))}
                                                                                        {/* <input
                                                                                            type="radio"
                                                                                            className="-input-radio"
                                                                                            id="bank-acc-51692356943"
                                                                                            name="checkboxacc"
                                                                                            defaultValue={5}
                                                                                        />
                                                                                        <label className="-label" htmlFor="bank-acc-51692356943">
                                                                                            <img
                                                                                                className="-logo"
                                                                                                src="/media/cache/strip/201910/84c0f97b9a4a02bd519f6256da291d06.png"
                                                                                                alt=""
                                                                                            />
                                                                                            <i className="fas fa-check" />
                                                                                        </label>
                                                                                        <input
                                                                                            type="radio"
                                                                                            className="-input-radio"
                                                                                            id="bank-acc-61692356943"
                                                                                            name="checkboxacc"
                                                                                            defaultValue={6}
                                                                                            data-gtm-form-interact-field-id={6}
                                                                                        />
                                                                                        <label className="-label" htmlFor="bank-acc-61692356943">
                                                                                            <img
                                                                                                className="-logo"
                                                                                                src="/media/cache/strip/201910/75cf7484ae422a0d3897e82556cb04fd.png"
                                                                                                alt=""
                                                                                            />
                                                                                            <i className="fas fa-check" />
                                                                                        </label>
                                                                                        <input
                                                                                            type="radio"
                                                                                            className="-input-radio"
                                                                                            id="bank-acc-71692356943"
                                                                                            name="checkboxacc"
                                                                                            defaultValue={7}
                                                                                        />
                                                                                        <label className="-label" htmlFor="bank-acc-71692356943">
                                                                                            <img
                                                                                                className="-logo"
                                                                                                src="/media/cache/strip/201910/ffcda61156271aa5d354b4d052590a14.png"
                                                                                                alt=""
                                                                                            />
                                                                                            <i className="fas fa-check" />
                                                                                        </label>
                                                                                        <input
                                                                                            type="radio"
                                                                                            className="-input-radio"
                                                                                            id="bank-acc-81692356943"
                                                                                            name="checkboxacc"
                                                                                            defaultValue={21}
                                                                                        />
                                                                                        <label className="-label" htmlFor="bank-acc-81692356943">
                                                                                            <img
                                                                                                className="-logo"
                                                                                                src="/media/cache/strip/201910/IMG_8955.PNG"
                                                                                                alt=""
                                                                                            />
                                                                                            <i className="fas fa-check" />
                                                                                        </label> */}

                                                                                        {/* 



                                                                                        <input
                                                                                            type="radio"
                                                                                            className="-input-radio"
                                                                                            id="bank-acc-91692356943"
                                                                                            name="checkboxacc"
                                                                                            defaultValue={20}
                                                                                        />
                                                                                        <label className="-label" htmlFor="bank-acc-91692356943">
                                                                                            <img
                                                                                                className="-logo"
                                                                                                src="/media/cache/strip/201910/IMG_8956.PNG"
                                                                                                alt=""
                                                                                            />
                                                                                            <i className="fas fa-check" />
                                                                                        </label>



                                                                                        <input
                                                                                            type="radio"
                                                                                            className="-input-radio"
                                                                                            id="bank-acc-111692356943"
                                                                                            name="checkboxacc"
                                                                                            defaultValue={19}
                                                                                        />
                                                                                        <label className="-label" htmlFor="bank-acc-111692356943">
                                                                                            <img
                                                                                                className="-logo"
                                                                                                src="/media/cache/strip/201910/IMG_8958.PNG"
                                                                                                alt=""
                                                                                            />
                                                                                            <i className="fas fa-check" />
                                                                                        </label>
                                                                                        <input
                                                                                            type="radio"
                                                                                            className="-input-radio"
                                                                                            id="bank-acc-121692356943"
                                                                                            name="checkboxacc"
                                                                                            defaultValue={18}
                                                                                        />
                                                                                        <label className="-label" htmlFor="bank-acc-121692356943">
                                                                                            <img
                                                                                                className="-logo"
                                                                                                src="/media/cache/strip/201910/IMG_8959.PNG"
                                                                                                alt=""
                                                                                            />
                                                                                            <i className="fas fa-check" />
                                                                                        </label>




                                                                                        <input
                                                                                            type="radio"
                                                                                            className="-input-radio"
                                                                                            id="bank-acc-141692356943"
                                                                                            name="checkboxacc"
                                                                                            defaultValue={14}
                                                                                        />
                                                                                        <label className="-label" htmlFor="bank-acc-141692356943">
                                                                                            <img
                                                                                                className="-logo"
                                                                                                src="/media/cache/strip/201910/de6513eee5e2d90848de36a1be081ef5.png"
                                                                                                alt=""
                                                                                            />
                                                                                            <i className="fas fa-check" />
                                                                                        </label>
                                                                                        <input
                                                                                            type="radio"
                                                                                            className="-input-radio"
                                                                                            id="bank-acc-1416923569411"
                                                                                            name="checkboxacc"
                                                                                            defaultValue={17}
                                                                                        />
                                                                                        <label className="-label" htmlFor="bank-acc-1416923569411">
                                                                                            <img
                                                                                                className="-logo"
                                                                                                src="/media/cache/strip/201910/IMG_8954.PNG"
                                                                                                alt=""
                                                                                            />
                                                                                            <i className="fas fa-check" />
                                                                                        </label> */}
                                                                                        {/* <input
                                                                                    type="radio"
                                                                                    className="-input-radio"
                                                                                    id="bank-acc-991692356943"
                                                                                    name="checkboxacc"
                                                                                    defaultValue={99}
                                                                                />
                                                                                <label className="-label" htmlFor="bank-acc-991692356943">
                                                                                    <img
                                                                                        className="-logo"
                                                                                        src="https://yt3.googleusercontent.com/aWp38-LVqL_fztxB3VkBaYHj6otE3dmCq-QMhEJUdRvDQbn1xU5gvU9CrB15x6-aWbLA9oQ1CQ=s900-c-k-c0x00ffffff-no-rj"
                                                                                        alt=""
                                                                                    />
                                                                                    <i className="fas fa-check" />
                                                                                </label> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="js-bank-number-and-name-container position-relative" style={{ color: "white" }}>
                                                                            <div className="x-bank-info-validate-box -v2">
                                                                                <div className="-content-validate">
                                                                                    သင့်အမည်နှင့် အကောင့်နံပါတ်ကို စစ်ဆေးပါ။!!
                                                                                </div>
                                                                                <div className="-detail-validate">
                                                                                    အမှားအယွင်းရှိပါက၊ ၎င်းကိုပြင်ရန် သို့မဟုတ် ငွေသွင်းခြင်း သို့မဟုတ် ငွေထုတ်ခြင်းတို့ကို ပြုလုပ်နိုင်မည်မဟုတ်ပါ။
                                                                                </div>
                                                                                <i className="fas fa-arrow-right" />
                                                                            </div>
                                                                            <div className="-x-input-icon mb-3 flex-column">
                                                                                <img
                                                                                    className="-icon"
                                                                                    alt="กระเป๋าเงิน ฝากถอน ออโต้"
                                                                                    width={15}
                                                                                    height={15}
                                                                                    src="/build/web/igame-index-lobby-wm/img/ic-library-book-v2.png?v=5"
                                                                                />
                                                                                <input
                                                                                    type="text"
                                                                                    onChange={this.onChangex}
                                                                                    id="accnumbertxt"
                                                                                    name="accnumbertxt"
                                                                                    required="required"
                                                                                    pattern="[0-9]*"
                                                                                    className="x-form-control form-control"
                                                                                    placeholder="ဘဏ်အကောင့်နံပါတ်ကိုဖြည့်ပါ"
                                                                                    autoFocus="autoFocus"
                                                                                    inputMode="text"
                                                                                />
                                                                            </div>
                                                                            <div className="-x-input-icon mb-3 flex-column">
                                                                                <img
                                                                                    className="-icon"
                                                                                    alt="รองรับการเข้าใช้งานหลายผู้เล่น 24 ชั่วโมง"
                                                                                    width={15}
                                                                                    height={15}
                                                                                    src="/build/web/igame-index-lobby-wm/img/ic-user-v2.png?v=5"
                                                                                />
                                                                                <input
                                                                                    type="text"
                                                                                    onChange={this.onChangex}
                                                                                    id="fname"
                                                                                    name="fname"
                                                                                    required="required"
                                                                                    className="x-form-control form-control"
                                                                                    placeholder="ဘဏ်အကောင့်အမည်ကိုဖြည့်ပါ"
                                                                                />
                                                                            </div>
                                                                            <div className="-text-hint col-sm-10 m-auto d-block d-lg-none">
                                                                                အချက်အလက်ကို ပြည့်စုံမှန်ကန်စွာ ဖြည့်ပါ။ မဟုတ်ပါက သင်သည် ငွေထုတ်ယူနိုင်မည် မဟုတ်ပါ။
                                                                            </div>
                                                                        </div>
                                                                        <a
                                                                            href="#0"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={(e) => {
                                                                                this.nextstep(e, 11)
                                                                            }} className="text-muted mt-3">
                                                                            ပယ်ဖျက်ခြင်း
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input
                                                                type="hidden"
                                                                id="customer_bank_account__token"
                                                                name="customer_bank_account[_token]"
                                                                defaultValue="EtuWE7npKiT8yTfHa4RNu7uFMEY1cTFVfBKaSda8kGc"
                                                            />

                                                        </div>
                                                        <div className="text-center">
                                                            <button
                                                                onClick={(e) => {
                                                                    this.nextstep(e, 2)
                                                                }}
                                                                type="submitxa"
                                                                className="btn   btn-primary mt-lg-3 mt-0"
                                                                disabled=""
                                                            >
                                                                <div className="-text-container"> <div className="textwidget"><a href="#">နောက်တစ်ဆင့်</a></div></div>
                                                            </button>
                                                        </div>
                                                        <input
                                                            type="hidden"
                                                            id="request_otp__token"
                                                            name="request_otp[_token]"
                                                            defaultValue="ds26nEjb1GacmW8racC7ScvGiCgTEnPl5xKWBzZyMWc"
                                                        />
                                                    </div>
                                                    <div className="-top " style={{ display: this.state.stateregis === 3 ? "" : "none" }}>
                                                        <h3 className="x-title-modal mx-auto text-center ">
                                                            မိမိလိုအပ်သောစကားဝှက်ကို၂ကြိမ်ထည့်ပြီးအတည်ပြုပါ
                                                        </h3>
                                                        <div className="-fake-inner-body">
                                                            <div className="x-step-register -v2">
                                                                <div className="px-0 m-auto -container-wrapper">
                                                                    <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                                                    <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                                                    <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                                                    <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                                                </div>
                                                            </div>
                                                            <div >
                                                                <div className="text-center">

                                                                    <div className="-x-input-icon mb-3 text-center">
                                                                        <img
                                                                            src="/build/web/igame-index-lobby-wm/img/ic-input-lock.png"
                                                                            className="-icon"
                                                                            alt="SA Casino phone icon"
                                                                            width={10}
                                                                            height={14}
                                                                        />
                                                                        <input
                                                                            onChange={this.onChangex}
                                                                            type="password"
                                                                            id="password"
                                                                            name="password"
                                                                            required="required"
                                                                            className="x-form-control form-control"
                                                                            placeholder="စကားဝှက်"
                                                                            autoFocus="autoFocus"
                                                                            autoComplete="on"
                                                                            inputMode="text"
                                                                        />
                                                                    </div>
                                                                    <div className="-x-input-icon mb-3 text-center">
                                                                        <img
                                                                            src="/build/web/igame-index-lobby-wm/img/ic-input-lock.png"
                                                                            className="-icon"
                                                                            alt="SA Casino phone icon"
                                                                            width={10}
                                                                            height={14}
                                                                        />
                                                                        <input
                                                                            onChange={this.onChangex}
                                                                            type="password"
                                                                            id="passwordcf"
                                                                            name="passwordcf"
                                                                            required="required"
                                                                            className="x-form-control form-control"
                                                                            placeholder="စကားဝှက်"
                                                                            autoFocus="autoFocus"
                                                                            autoComplete="on"
                                                                            inputMode="text"
                                                                        />
                                                                        <div className="mt-3 -spacer  text-center" style={{ display: (this.state.errorstatus ? "" : "none") }}>
                                                                            <div className="x-danger-zone -v2  text-center" style={{color:"red"}}>
                                                                                <span>{this.state.errortext}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <a href="#0" style={{ cursor: "pointer" }}
                                                                        onClick={(e) => {
                                                                            this.nextstep(e, 22)
                                                                        }} className="text-muted mt-3">
                                                                        အတည်ပြု
                                                                    </a>
                                                                    <button
                                                                        onClick={(e) => {
                                                                            this.validatergisterForm(e)
                                                                        }}
                                                                        type="submitx"
                                                                        className="btn btn-primary mt-lg-3 mt-0 -submit"
                                                                        disabled=""
                                                                    >

                                                                        <div className="-text-container"> <div className="textwidget"><a href="#">အတည်ပြုပါ။</a></div></div>
                                                                    </button>
                                                                </div>
                                                                <input
                                                                    type="hidden"
                                                                    id="request_otp__token"
                                                                    name="request_otp[_token]"
                                                                    defaultValue="ds26nEjb1GacmW8racC7ScvGiCgTEnPl5xKWBzZyMWc"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="-bottom ">
                                                        <div className="x-admin-contact text-center ">
                                                            <span className="x-text-with-link-component">
                                                                <label className="-text-message ">ပြဿနာကြုံတွေရင်  ဆက်သွယ်ရန်</label>
                                                                <a
                                                                    href={this.state.linelink}
                                                                    className="-link-message "
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <u>ဆက်သွယ်ရန်</u>
                                                                </a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        );
    }
}
export default App;
