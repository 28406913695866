import React, { Component } from 'react'; //different
import SweetAlert from 'react-bootstrap-sweetalert';
//import Paoyingchub from './game/paoyingchub';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
// import './style.css';
import System from "./SystemFunction";
import NavMenu from './navmenu';
import Systems from "./SystemFunction";
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            playerid: null,
            user_id: null,
            point: null,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
        }

    }

    redeem = (e) => {
        e.preventDefault();
        this.checklogin();
        //////console.log('tag', e.target.href);
        if (!this.state.loginstate) {
            var title = 'Not login!'
            var text = ' คุณไม่ได้ทำการ Login'
            var object = { title, text }
        }
    }


    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            this.setState({
                user_id: decoded.message.playerid,
                playerid: decoded.message.member_id,
                firstname: decoded.message.firstname,
                lastname: decoded.message.lastname,
                point: decoded.message.Point,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }


    async componentDidMount() {
        try {
            var token = localStorage.getItem('auth_token');
            const search = window.location.href;
            const params = new URLSearchParams(search);
            this.checklogin();
            await instance.post("/api/v1/tabgamelist", {
                state: "2",
                System: Systems,
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.setState({
                            categorylist: res.data.message
                        });
                    }
                    else {
                    }
                });
        }
        catch (ex) {
            // console.log(ex);
        }

    }

    render() {

        return (
            <div className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap" data-filter-default="All"   >
                {this.state.categorylist.map((listitem, i) => (
                    <div className="uael-grid-item  uael-img-gallery-item-1">
                        <a key={i} id={listitem.gameid} href={"/listgameall/slot/" + listitem.partner}>
                            <div className="uael-grid-item-content">
                                <div className="uael-grid-img-thumbnail uael-ins-target">
                                    <img
                                        loading="lazy"
                                        decoding="async"
                                        width={224}
                                        height={300}
                                        src={listitem.imagesec}
                                        className="attachment-medium size-medium"
                                        alt=""
                                    />
                                </div>
                                <div className="uael-grid-img-overlay" />
                            </div>
                        </a>
                    </div>

                ))}
            </div>

        );

    }
}
export default App;
