import React, { Component } from 'react'; //different
import './App.css';
import Footer from './footer';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
import instance from "./axios-instance";
import { NumericFormat } from 'react-number-format';




class App extends Component {

     componentDidMount() {
        ////console.log(23565);
        this.logout();
    }
    
    validateLogout = (e) => {
        console.log("logout")
        localStorage.removeItem('auth_token', '')
        localStorage.removeItem('cookie', '')
        this.setState({
            userid: null,
            username: null,
            password: null,
            firstname: null,
            lastname: null,
            point: null,
            loginstate: false,
        });
    }

    logout = (e) => {
        // e.preventDefault();
        let errors = this.validateLogout();
        if (errors === true) {
            // alert("You are successfully signed in...");
            window.location.href = '/';
        } else {

            this.setState({
                errors: errors,
                formSubmitted: true
            });
            window.location.href = '/';
        }
    }
    render() {
        return (
            <nav className="x-header navbar navbar-expand-lg -anon">
             
            </nav>


        );
    }
}
export default App;
