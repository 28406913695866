import React, { Component } from 'react'; //different
import SweetAlert from 'react-bootstrap-sweetalert';
//import Paoyingchub from './game/paoyingchub';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
//import './build/web/igame-index-lobby-wm/style.a57427aa.css';
import { isMobile } from 'react-device-detect';
import NavMenu from './navmenu';
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            username: null,
            userid: null,
            point: null,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
            typepage: null
        }
        // this.handleClick = this.handleClick.bind(this);
        // this.handlePageChange = this.handlePageChange.bind(this);
        // this.hideAlert = this.hideAlert.bind(this);

    }






    redeem = (e, gameIdx, partner) => {
        var userAgent = navigator.userAgent
        this.checklogin();
        if (!this.state.loginstate) {
            //////console.log(5465656);
            return document.getElementById("loginmode").click();
        }
        if (this.state.username !== null) {
            instance.post("/api/v1/tablauncher", {
                gameId: gameIdx,
                partner: partner,
                playerUsername: this.state.userid,
                isMobile: isMobile,
                System: Systems,
                demo: true
                
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        //////console.log(res.data.message);
                        var test = res.data.message.launcher
                        if (test !== undefined) {
                     
                            if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {            
                                window.location.href = test.mobile;
                              //  window.open(test.mobile,'_blank');
                            }
                            else {
                                if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                                else window.open(test.mobile);
                            }
                        }
                        else if (test === undefined) {
                            Swal.fire({
                                title: 'error!',
                                text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                                icon: 'error',
                                confirmButtonText: 'သဘောတူသည်။'
                            })
                        }
                    }
                    else {
                        //  this.CheckThisGoalError();
                    }
                });
        }
        else {

        }


    }

    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            //////console.log(decoded);
            this.setState({
                userid: decoded.message.playerid,
                username: decoded.message.playerid,
                firstname: decoded.message.firstname,
                lastname: decoded.message.lastname,
                point: decoded.message.Point,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }

    componentDidMount() {
        this.checklogin();
        this.checkonline();
    }

    checkonline() {
        //console.log(1);
        const search = window.location.href;
        const params = new URLSearchParams(search);
        const space = search.split("/")
        this.setState({
            typepage: space[4].replace("#", ""),
        });
        instance.post("/api/v1/tabgamelist", {
            state: "998",
            System: Systems,
            partner: space[5].replace("#", ""),
            game: space[4].replace("#", ""),
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        categorylist: res.data.message
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });
    }

    render() {
        if (this.state.categorylist.length > 0) {
            return (
                <section className="x-index-top-container" >
                    <div className="-index-inner-wrapper" style={{ backgroundImage: "url(/build/web/igame-index-lobby-wm/img/index-bg.jpg)" }}>
                        <div className="d-lg-none">
                            <div className="x-feed-news-header">
                                <div className="-feed-news-inner-wrapper" data-animatable="fadeInUp" data-delat={200}>
                                    <div className="-icon-container">
                                        <i className="fas fa-volume-up" />
                                    </div>
                                    <div className="-track">
                                        <div className="-track-item -duration-normal-content">
                                     bossy777 သည် 2024 ခုနှစ်တွင်မြန်မာပြည်ထိပ်တန်းအွန်လိုင်းကာစီနိုဂိမ်းပလက်ဖောင်းဖြစ်ပြီး ပေါက်ရလွယ်ကူသောစလော့ဂိမ်းများကိုခံစားလိုက်ပါ
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="-games-provider-wrapper" >
                            <NavMenu />
                            <div className="-games-index-page" >
                                <h2 className="-heading-sub-title">Deepluss Casino คาสิโนออนไลน์เต็มรูปแบบ ตื่นตาตื่นใจไปกับทุกการเดิมพัน</h2>
                                <div className="x-category-provider js-game-scroll-container js-game-container">
                                    <div className="-games-list-container container">
                                        <nav className="nav-menu" id="navbarProvider">
                                            <ul className="nav nav-pills">
                                                {this.state.categorylist.map(listitem => (
                                                    <li key={listitem.id} id={listitem.id} className="nav-item -game-casino-macro-container">
                                                        <a
                                                            href={() => false}
                                                            onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}
                                                            className="nav-link js-account-approve-aware"
                                                            rel="nofollow noopener"
                                                        >
                                                            <div className="x-game-list-item-macro js-game-list-toggle -big " data-status>
                                                                <div className="-inner-wrapper">
                                                                    <picture>
                                                                        <img src={listitem.image} className="-cover-img lazyload img-fluid" alt="dream-gaming" width={364} height={231} />
                                                                    </picture>
                                                                    <div className="-overlay">
                                                                        <div className="-overlay-inner">
                                                                            <div className="-wrapper-container">
                                                                                <button href="#" className="-btn -btn-play js-account-approve-aware"  >
                                                                                    <i className="fas fa-play" />
                                                                                    <span className="-text-btn">เข้าเล่น</span>
                                                                                </button>
                                                                                <button
                                                                                    id="loginmode"
                                                                                    href="#loginModal"
                                                                                    className="-btn -btn-play js-account-approve-aware"
                                                                                    data-toggle="modal"
                                                                                    data-target="#loginModal"
                                                                                    style={{ display: "none" }}
                                                                                >
                                                                                    <i className="fas fa-play" />
                                                                                    <span className="-text-btn">เข้าเล่น</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="-title">{listitem.providername}</div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

            );
        } else {

        }
    }
}
export default App;
