import React, { Component } from 'react'; //different
import SweetAlert from 'react-bootstrap-sweetalert';
//import Paoyingchub from './game/paoyingchub';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
//import './build/web/igame-index-lobby-wm/style.a57427aa.css';
import { isMobile } from 'react-device-detect';
import NavMenu from './navmenu';
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
import md5 from 'md5';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            mainsetting: [],
            rewardlist: [],
            activePage: 1,
            pagecount: 1,
            products_page_count: 1,
            isActive: false,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            prolistx: [],
            username: null,
            userid: null,
            showmodal: false,
            phoneNumber: "",
            balance: 0,
            mainacc: null,
            qrcode: null,
            stateregis: 1,
            checkboxacc: 0,
            loginstate: false,
            fullname: null,
            lname: null,
            alert: null,
            depositstate: 0,
            depositamount: 0,
            token: window.localStorage.getItem("token"),
            accnumber: null,
            accnumbertxt: null,
            withdrawamount: 0,
            rankpoint: 0,
            changepassword: false,
            linelink: "",
            errortext: "",
            affpercent: 0,
            userrecomend: "",
            historylist: [],
            useradvice: [],
            tblnextrank: [],
            tblrankall: [],
            tblprofile: [],
            prodetail: [],
            linkaff: "",
            picnonpro: "/media/cache/strip/202109/promotion/7AB3F352-6DE2-44CC-A5FC-D4359FCB2122.png",
            proderedeem: [],
            buttoncliam: false,
            limit: null,
            rankpic: null,
            rankname: null,
            rankob: null,
            termAndCondition: false,
            errorstatus: false,
        }
    }
    confirmdeposit = async (e, num) => {

        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;

        await instance.post("/api/v1/getredeempromotion", {
            userid: Username,
            System: Systems,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                this.setState({
                    proderedeem: res.data.message.tblpromotionredeems,
                });

            }
        }).catch(async (err) => {
        });

        if (num === 0) {
            if ((this.state.depositamount >= this.state.limit.min) && (this.state.depositamount <= this.state.limit.max)) {
                const { depositstate } = this.state
                ////////console.log(depositstate, num);
                if (num === 0) {
                    if (token != null) {
                        instance.post("/api/v1/getqrcode", {
                            username: Username,
                            System: Systems,
                            amount: this.state.depositamount
                        }, {
                            headers: { Authorization: `Bearer ${token}` }
                        }).then(async (res) => {
                            ////////console.log(res.data.picture)
                            this.setState({
                                mainacc: res.data.mainacc,
                                qrcode: res.data.picture
                            });
                        });
                    }

                    this.setState({
                        depositstate: 1
                    });
                }
            }
            else {
                await this.notify("success", 'เติมขั้นต่ำ ' + this.state.limit.min + ' บาท สูงสุด ' + this.state.limit.max + ' ');
            }
        }
        else if (num === 1) {
            this.setState({
                depositstate: 1
            });
        }
        else {
            this.setState({
                depositstate: 0
            });
        }



    }
    async componentDidMount() {
        this.promotionlist();
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            this.setState({
                username: Username,
            });

        }
    }
    promotionlist = () => {
        instance.post("/api/v1/getpromotion", {
            System: Systems,
        }).then(async (res) => {
            if (res.data.status === 200) {
                this.setState({
                    prolistx: res.data.message,
                });
            }
        }).catch(async (err) => {
            //   console.log(err);

            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await Swal.fire({
                    title: 'Warning!',
                    text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
                window.location.href = '/';
            }
        });
    }
    redeempro = async (e, proid) => {
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        //console.log(Username, proid);
        if (token != null) {
            this.setState({
                username: Username,
            });
            await instance.post("/api/v1/redeempromotion", {
                userid: Username,
                System: Systems,
                Proid: proid,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //console.log(res.data.status)
                if (res.data.status === 200) {
                    this.setState({
                        showmodal: false,
                    })
                }
                else if (res.data.status === 201) {
                    await this.notify("success", 'ท่านไม่เข้าเงื่อนใขပရိုးမိုရရှင်းนี้');
                    e.preventDefault();
                    return false;
                }
                else if (res.data.status === 204) {
                    e.preventDefault();

                    await this.notify("success", 'ท่านใช้สิทธวันนี้ครบแล้ว');
                    return false;
                }
                else if (res.data.status === 202) {
                    e.preventDefault();

                    await this.notify("success", 'ท่านใช้โปรมั่นชั่นนี้ไปแล้ว');
                    return false;
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await this.notify("success", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                    window.location.href = '/';
                }
            });
        }
    }

    checkpro = async (e, proid) => {
        console.log(248, proid);
        var Username = "";
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            Username = decoded.message.playerid;
        }
        await instance.post("/api/v1/checkpromotion", {
            userid: Username,
            System: Systems,
            Proid: proid,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            console.log(res.data)
            if (res.data.status === 200) {
                //console.log(res.data);
                if (res.data.type === 0) {
                    this.setState({
                        showmodal: true,
                        prodetail: res.data.message.tblpromotions[0],
                    });
                }
                else if (res.data.type === 1) {
                    this.setState({
                        showmodal: true,
                        proderedeem: res.data.message.tblpromotionredeems,
                    });
                }



            }
            else if (res.data.status === 201) {
                this.setState({
                    showmodal: true,
                    prodetail: [],
                    picnonpro: "/media/cache/strip/202109/promotion/7AB3F352-6DE2-44CC-A5FC-D4359FCB2122.png",
                });

            }
            else if (res.data.status === 202) {
                await this.notify("success", 'ท่านใช้ပရိုးမိုရရှင်းค้างไว้ สามารถเติมเงินได้เลย');
                return false;
            }

        }).catch(async (err) => {
            //   console.log(err);
            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await this.notify("success", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                window.location.href = '/';
            }
        });
    }
    couponredeem = async (e, index) => {
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            await instance.post("/api/v1/usercoupon", {
                User_id: Username,
                System: Systems,
                couponkey: this.state.couponkey,
                type: 1
            }).then(async (res) => {
                if (res.data.status === 200) {
                    const datas = res.data.message;
                    await Swal.fire({
                        title: 'Success!',
                        text: 'ดำเนินการใช้คูปองเรียบร้อย',
                        icon: 'success',
                        confirmButtonText: 'ตกลง'
                    })
                    await this.notify("success", 'ดำเนินการใช้คูปองเรียบร้อย');
                }
                else {
                    await this.notify("error", 'ไม่มี Key นี้ในระบบ');
                }
            });
        }
        else {
            await this.notify("error", 'คุณไม่ได้ล๊อคอิน');
        }

    }
    notify = async (mode, msg) => {
        console.log(mode, msg);
        if (mode == "success") {
            await toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "warning") {
            await toast.warning(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "error") {
            await toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "info") {
            await toast.info(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        await this.waitnotify();
    }
    waitnotify = async () => {
        setTimeout(() => { console.log('World!') }, 5000)
    }
    onChangex = event => {
        // console.log("event", event.target.name);


        if (event.target !== undefined) {
            let nam;
            let val;
            if (event.target.name === "otpphone") {
                document.getElementById("otpphone").style = "";
                document.getElementById("checkotp").style.display = "none";
            }
            else if (event.target.name === "phoneNumber") {
                document.getElementById("phoneNumberforget").style = "";
                document.getElementById("checkotpforgetphone").style.display = "none";
            }
            else if (event.target.name === "otpphoneforget") {
                document.getElementById("otpphoneforget").style = "";
                document.getElementById("checkotpforget").style.display = "none";
            }
            else if (event.target.name === "otpphoneforget") {
                document.getElementById("phoneNumber").style = "";
                document.getElementById("checkotpforgetphonenormal").style.display = "none";
            }

            if (event.target.name === undefined) {
                nam = event.name;
                val = event.value;
            } else {
                nam = event.target.name;
                val = event.target.value;
            }
            this.setState({ [nam]: val });
        }
    };
    render() {
        const { prodetail, picnonpro, username, prolistx } = this.state
        return (
            <>
                <div className="padding-router-view">
                    <div className="container p-0 x-margin-top-v3">
                        <div className="row m-0 border-shadow-title">
                            <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                                <div className="text-center bg-title-web">
                                    <div>
                                        <div className="text-title text-white-v1 text-center">
                                            <div className="d-flex justify-content-between">
                                                <div className="text-white-v1-deposit">
                                                    <a role="button" href='/'>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={25}
                                                            height={25}
                                                            fill="currentColor"
                                                            className="bi bi-arrow-left-circle text-color-back"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                                                            />
                                                        </svg>
                                                    </a>
                                                </div>
                                                <div className="text-white-v1-deposit">
                                                    <h5>ပရိုးမိုရရှင်း</h5>
                                                </div>
                                                <div>{/**/}</div>
                                            </div>
                                            <hr className="mt-0" />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    data-v-ab1d19ba=""
                                    className="auction animated animatedFadeInUp fadeInUp"
                                    role="button"
                                >
                                    <div
                                        data-v-ab1d19ba=""
                                        className="auction__body-menu auction__body--border-radius"
                                    >
                                        <div data-v-ab1d19ba="" className="tab text-white-v1">
                                            <div className="mb-1">
                                                <label htmlFor="price" className="mt-1 mb-1 text-left">
                                                    Code Coupon
                                                </label>
                                                <div className="-x-input-icon flex-column">
                                                    <div className="input-group mb-1">
                                                        <input
                                                            type="text"
                                                            onChange={this.onChangex}
                                                            id="couponkey"
                                                            name="couponkey"
                                                            className="bg-border-input form-control form-control-form form-not-input-login-register"
                                                            placeholder=""
                                                            aria-label={0}
                                                            aria-describedby="basic-addon1"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-2 mb-2">
                                                {/**/}
                                                <div>
                                                    <div>
                                                        <button
                                                            onClick={(e) => {
                                                                this.couponredeem(e)
                                                            }}
                                                            type="submit"
                                                            className="btn -submit js-submit-accept-term v-login mt-0 w-100 p-2 mb-4"
                                                        >
                                                            အတည်ပြု
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="text-white-v1-deposit text-center mb-4">
                                                    <div className="box-content p-0">
                                                        <div className="sub-content p-3">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="text-white-v1-deposit pt-1">
                                                                    <img
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                                        alt=""
                                                                        style={{ width: 30 }}
                                                                    />{" "}
                                                                    &nbsp; ပရိုမိုးရှင်းကိုအရင်နှိပ်ပြီးမှငွေသွင်းပါ ပရိုမိုးရှင်းအော်တိုဝင်သွားပါမည်
                                                                </div>
                                                                <div className="text-white-v1-deposit">
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            className="form-check-input"

                                                                            type="checkbox"
                                                                            role="switch"
                                                                            id="flexSwitchCheckDefault"
                                                                            style={{ width: "6em", padding: 12 }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-2 mb-4">
                                                <label htmlFor="price" className="mb-1 text-left">
                                                    စုစုပေါင်းမိတ်ဆက်သောမန်ဘာ
                                                </label>
                                                <div className="text-left">
                                                    <small>
                                                        <b>မှတ်ချက်  :</b>: "
                                                        ပရိုမိုးရှင်းအချက်အလက်အတိုင်းမှန်ကန်စွာပြုလုပ်တာနဲ့  ပရိုမိုးရှင်းကိုလက်ခံရယူနိုင်ပါသည်{" "}
                                                        {/**/}"{" "}
                                                    </small>
                                                </div>
                                                <div className="p-1">
                                                    <div className="row">
                                                        {prolistx.map(prolists => (
                                                            <div className="text-center mb-4 col-12 col-lg-4">
                                                                <a
                                                                    href="#0"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={(e) => this.checkpro(e, prolists.no)}
                                                                >
                                                                    <div
                                                                        className="p-1 bg-mission-button example"
                                                                    //  data-bs-toggle="modal"
                                                                    //href="#show_img"
                                                                    >
                                                                        <img
                                                                            className="w-100"
                                                                            data-action="zoom"
                                                                            src={prolists.linkpicture}
                                                                            alt={prolists.linkpicture}
                                                                            style={{ borderRadius: 13 }}
                                                                        />
                                                                        <div className="text position-auto-text">
                                                                            {prolists.proname}
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="base-modal-overlay x-modal modal -v2 x-theme-switcher-v2 show animated animatedFadeInUp fadeInUp"
                    data-v-0c0c7fd4=""
                    style={{ display: this.state.showmodal ? "" : "none" }}
                >
                    <div
                        className="base-modal pt-0 pb-0 w-100"
                        data-v-8bc7c3b9=""
                        style={{ margin: 10, textAlign: "-webkit-center" }}
                    >
                        <div
                            className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable modal-dialog-centered w-100 m-0"
                            data-v-8bc7c3b9=""
                            style={{ height: "auto" }}
                        >


                            <div
                                className="modal-content -modal-content text-white-v1"
                                data-v-8bc7c3b9=""
                            >
                                <a
                                    onClick={(e) => {
                                        this.setState({
                                            showmodal: false
                                        });
                                    }}
                                    role="button"
                                    className="close-model-head mt-4 mb-4"
                                    data-v-8bc7c3b9=""
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={30}
                                        height={30}
                                        fill="currentColor"
                                        className="bi bi-x-circle-fill"
                                        viewBox="0 0 16 16"
                                        data-v-8bc7c3b9=""
                                    >
                                        <path
                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
                                            data-v-8bc7c3b9=""
                                        />
                                    </svg>
                                </a>


                                <div className="pt-3 pb-4 x-theme-switcher-v2 text-center mt-3"
                                    data-v-8bc7c3b9=""
                                >
                                    {(prodetail.length === 0) ?
                                    
                                        <div>
                                            <img
                                                style={{ display: (prodetail.linkpicture !== null) ? "" : "none" }}
                                                src={picnonpro}
                                                alt="img_show_popup"
                                                className="img_show_popup"
                                            />
                                            <div className="box-content-pro mt-2 pt-2 pb-2">
                                                <div className="sub-content-pr0 p-2">စည်းကမ်းနှင့်သတ်မှတ်ချက်များ</div>
                                            </div>
                                            
                                            <div  style={{ display: (prodetail.prolist == 5) ? "none" : "" }}>
                                                <a
                                                    className="btn "
                                                    style={{ fontWeight: "300", background: "radial-gradient(100% 100% at 50% 0, #f9cf95 0, #c69a5d 100%)", display: (username !== null) ? "" : "none" }}
                                                    onClick={(e) => this.redeempro(e, prodetail.no)}
                                                >
                                                    <span>ပရိုးမိုးရှင်းလက်ခံ</span>
                                                </a>
                                            </div>
                                            <div className="text-left"> <pre
                                                className="text-color-promotion"
                                                style={{ whiteSpace: "pre-line" }}
                                            >ပရိုမိုးရှင်းကို အသက်မသွင်းရသေးပါ။
                                            </pre>
                                            </div> </div> : <div>
                                            <img

                                                style={{ display: (prodetail.linkpicture !== null) ? "" : "none" }}
                                                src={prodetail.linkpicture}
                                                alt="img_show_popup"
                                                className="w-100"
                                            />
                                            <div className="box-content-pro mt-2 pt-2 pb-2">
                                                <div className="sub-content-pr0 p-2">စည်းကမ်းနှင့်သတ်မှတ်ချက်များ</div>
                                            </div>
                                            <div  style={{ display: (prodetail.prolist == 5) ? "none" : "" }}>
                                                <a
                                                    className="btn "
                                                    style={{ fontWeight: "300", background: "radial-gradient(100% 100% at 50% 0, #f9cf95 0, #c69a5d 100%)", display: (username !== null) ? "" : "none" }}
                                                    onClick={(e) => this.redeempro(e, prodetail.no)}
                                                >
                                                    <span>ပရိုးမိုးရှင်းလက်ခံ</span>
                                                </a>
                                            </div>
                                            <div className="text-left" style={{ color: "white" }}>
                                                <section
                                                    dangerouslySetInnerHTML={{ __html: prodetail.detail }}
                                                />
                                            </div>
                                  
                                        </div>}
                                </div>
                                {/**/}
                            </div>
                        </div>
                    </div>
                    {/**/}
                </div>

            </>

        );

    }
}
export default App;
