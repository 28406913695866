
import './App.css';
import React, { Component } from 'react'; //different
import instance from "./axios-instance";
import Systems from "./SystemFunction";
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            activePage: 1,
            isActive: false,
            rewardlist: [],
            activeTab: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            pagecount: 1,
            username: null,
            userid: null,
            point: 0,
            balance: 0,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
            modalOpen: false,
            handleClose: false,
            token: window.localStorage.getItem("token"),
            type: "",
            amount: "",
            remark: "",
            name: "",
            password: "",
            linelink: "",
            left: false,
            changepassword: false,
        }
    }
  async  componentDidMount() {
        this.setState({
            pathname: window.location.pathname.replace("/", "").trim()
        });
        try {
            await instance.post("/api/v1/mainsetting", {
                System: Systems,
              }).then((res) => {
                    if (res.data.status === 200) {
                        const datas = res.data.message;
                        this.setState({
                            linelink: datas[1].value
                        });
                    }
                    else {
                        // this.CheckThisGoalError();
                    }
                });
        }
        catch (error) {
            //////console.log();
        }
    }
    a
    render() {
        const pathname = this.state.pathname
        return (<>
        </>
        );
    }
}

export default App;
